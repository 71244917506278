var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm.$options.components['profile-need-help'] ? [_c('profile-need-help')] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('h4', {
    staticClass: "mt-3 mb-3"
  }, [_vm._v("Call Seller")]), _c('div', {
    staticClass: "card p-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start"
  }, [_c('div', [_c('b-img', {
    staticClass: "mr-3",
    attrs: {
      "src": "img/hero.png",
      "rounded": "circle",
      "width": "100px",
      "alt": "seller_image"
    }
  })], 1), _c('div', {}, [_c('h4', [_c('b', [_vm._v("Seller: " + _vm._s(_vm.sellerDetails[0].name))])]), _c('p', [_c('b-icon', {
    staticClass: "call-seller-phone",
    attrs: {
      "icon": "telephone-fill"
    }
  }), _vm._v(" Phone Number:" + _vm._s(_vm.sellerDetails[0].phone))], 1)])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }