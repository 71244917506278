<template>
  <div class="container">
    <b-row>
      <b-col md="3">
        <template v-if="$options.components['profile-need-help']">
          <profile-need-help />
        </template>
      </b-col>

      <b-col md="9">
        <h4 class="mt-3 mb-3">Call Seller</h4>
        <div class="card p-4">
          <div class="d-flex justify-content-start">
          <div>
          <b-img src="img/hero.png" rounded="circle" width="100px" class="mr-3" alt="seller_image"></b-img>
          </div>
          <div class="">
            <h4><b>Seller: {{ sellerDetails[0].name }}</b></h4>
            <p><b-icon icon="telephone-fill" class="call-seller-phone"></b-icon> Phone Number:{{ sellerDetails[0].phone }}</p>
          </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapState } from "vuex";

export default {
  name: "order-detail",
  data(){
    return{
      orderNumber: "",
    }
  },
  computed: {
    ...mapState({
      orderDetailsData: (state) => state.profile.orderDetailsData,
      orderList: (state) => state.profile.orderList,
      sellerDetails(state) {
        let sellerData = [];
        if (
          Array.isArray(state.profile.orderList) &&
          state.profile.orderList.length > 0
        ) {
          state.profile.orderList.filter((obj) => {
            {
              let arrObj = {
                id: obj.orderId,
                name: obj.items[0]?.providerName || '',
                phone: obj.provider.office.phone[0].number || '',
              };
              sellerData.push(arrObj);
            }
          });
          let filteredSellerData = sellerData.filter((obj)=>{
            return (this.orderNumber === obj.id);
          })
          return filteredSellerData;
        } else {
          this.isloading = false;
          return [];
        }
      }

    }),

  },
  async mounted(){
    await this.$store.dispatch("profile/fetchOrderList");
  },
  beforeUpdate() {
    this.orderNumber = this.$route.params.orderId;
  },
};
</script>


<style scoped>
  .container {
    min-height: 458px;
  }
</style>